<template>
  <div class="card_body">
    <div class="page-content page-container" id="page-content">
      <div class="padding">
        <div class="row container d-flex justify-content-center">
          <div class="col-xl-6 col-md-12">
            <div class="card user-card-full">
              <div class="row m-l-0 m-r-0">
                <div class="col-sm-4 bg-c-lite-green user-profile">
                  <div class="card-block text-center text-white">
                    <div>
                      <img
                        style="border-radius: 50%"
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtCEECs7smmbXf9Ig3Lf2HdMx1bVVWPJ9Scg&usqp=CAU"
                        class="img-radius"
                        alt="User-Profile-Image"
                      />
                    </div>
                    <!-- <h6 class="f-w-600">Hembo Tingor</h6>
                    <p>Web Designer</p>
                    <i
                      class="
                        mdi mdi-square-edit-outline
                        feather
                        icon-edit
                        m-t-10
                        f-16
                      "
                    ></i> -->
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="card-block">
                    <h6 class="m-b-20 p-b-5 b-b-default f-w-600">
                      {{
                        lang == "uz"
                          ? "Foydalanuvchi ma'lumotlari:"
                          : "Информация о пользователе:"
                      }}
                    </h6>
                    <div class="row">
                      <div class="col-sm-6">
                        <p class="m-b-10 f-w-600">
                          {{ lang == "uz" ? "Ismi:" : "Имя:" }}
                        </p>
                        <div class="input_field">
                          <input
                            class="inputField"
                            type="text"
                            name="first_name"
                            :placeholder="lang == 'uz' ? 'Ismi' : 'Имя'"
                            v-model="first_name"
                            required
                          />
                        </div>
                        <p class="m-b-10 f-w-600">
                          {{ lang == "uz" ? "Familiyasi:" : "Фамилия:" }}
                        </p>
                        <div class="input_field">
                          <input
                            class="inputField"
                            type="text"
                            name="last_name"
                            :placeholder="
                              lang == 'uz' ? 'Familiyasi' : 'Фамилия'
                            "
                            v-model="last_name"
                            required
                          />
                        </div>
                        <p class="m-b-10 f-w-600">
                          {{ lang == "uz" ? "Otasining ismi:" : "Отчество:" }}
                        </p>
                        <div class="input_field">
                          <input
                            class="inputField"
                            type="text"
                            name="middle_name"
                            :placeholder="
                              lang == 'uz' ? 'Otasining ismi' : 'Отчество'
                            "
                            v-model="middle_name"
                            required
                          />
                        </div>
                        <p class="m-b-10 f-w-600">
                          {{
                            lang == "uz" ? "Mutaxassislik:" : "Специальность:"
                          }}
                        </p>
                        <div class="input_field">
                          <input
                            class="inputField"
                            type="text"
                            name="specialization"
                            :placeholder="
                              lang == 'uz' ? 'Mutaxassislik' : 'Специальность'
                            "
                            v-model="specialization"
                            required
                          />
                        </div>
                        <p class="m-b-10 f-w-600">
                          {{
                            lang == "uz"
                              ? "Malaka/Tajriba:"
                              : "Квалификация/Опыт:"
                          }}
                        </p>
                        <div class="input_field">
                          <input
                            class="inputField"
                            type="text"
                            name="qualification"
                            :placeholder="
                              lang == 'uz'
                                ? 'Malaka/Tajriba'
                                : 'Квалификация/Опыт'
                            "
                            v-model="qualification"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <button class="add_btn" @click="editData()">
                        {{ lang == "uz" ? "Saqlash" : "Сохранять" }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios.js";
export default {
  data() {
    return {
      qualification: "",
      specialization: "",
      first_name: "",
      last_name: "",
      middle_name: "",
      lang: null,
    };
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
    axios.get("/api/doctors/" + localStorage.getItem("role_id")).then((res) => {
      this.qualification = res.data.qualification;
      this.specialization = res.data.specialization;
      this.last_name = res.data.lastName;
      this.first_name = res.data.firstName;
      this.middle_name = res.data.middleName;
    });
  },
  methods: {
    editData() {
      if (
        this.last_name &&
        this.middle_name &&
        this.first_name &&
        this.specialization &&
        this.qualification
      ) {
        axios
          .patch("/api/doctors/" + localStorage.getItem("role_id"), {
            qualification: this.qualification,
            specialization: this.specialization,
            firstName: this.first_name,
            lastName: this.last_name,
            middleName: this.middle_name,
          })
          .then(() => {
            this.$toast.success("Ma'lumotlar tahrirlandi !", {
              position: "top-right",
              timeout: 5000,
              draggablePercent: 0.6,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
            });
          })
          .catch(() => {
            this.$toast.error("Ma'lumotlarni yuklashda xatolik yuz berdi!", {
              position: "top-right",
              timeout: 5000,
              draggablePercent: 0.6,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
            });
          });
      } else {
        this.$toast.error("Maydonlarni to'ldiring!", {
          position: "top-right",
          timeout: 5000,
          draggablePercent: 0.6,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$yellow: #1890ff;
$black: #000000;
$grey: #1890ff;
.card_body {
  background-color: #f9f9fa;
}
.padding {
  padding: 3rem !important;
}

.user-card-full {
  overflow: hidden;
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
}

.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.user-card-full .user-profile {
  border-radius: 5px 0 0 5px;
}

.bg-c-lite-green {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f29263),
    to(#ee5a6f)
  );
  background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
  padding: 20px 0;
}

.card-block {
  padding: 1.25rem;
}

.m-b-25 {
  margin-bottom: 25px;
}

.img-radius {
  border-radius: 5px;
}

h6 {
  font-size: 14px;
}

.card .card-block p {
  line-height: 25px;
}

@media only screen and (min-width: 1400px) {
  p {
    font-size: 14px;
  }
}

.card-block {
  padding: 1.25rem;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
  margin-bottom: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.card .card-block p {
  line-height: 25px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.text-muted {
  color: #919aa3 !important;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
  font-weight: 600;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-40 {
  margin-top: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-40 {
  margin-top: 20px;
}

.user-card-full .social-link li {
  display: inline-block;
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.input_field {
  position: relative;
  margin-bottom: 20px;
  -webkit-animation: bounce 0.6s ease-out;
  animation: bounce 0.6s ease-out;
  > span {
    position: absolute;
    left: 0;
    top: 0;
    color: #333;
    height: 100%;
    border-right: 1px solid $grey;
    text-align: center;
    width: 30px;
    > i {
      padding-top: 10px;
    }
  }
}
.textarea_field {
  > span {
    > i {
      padding-top: 10px;
    }
  }
}
input {
  &[type="text"],
  &[type="date"],
  &[type="email"],
  &[type="number"] {
    width: 50%;
    padding: 8px 10px 9px 10px;
    height: 35px;
    border: 1px solid $grey;
    border-radius: 6px;
    box-sizing: border-box;
    outline: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  &[type="text"]:hover,
  &[type="date"]:hover,
  &[type="number"]:hover {
    background: #fafafa;
  }
  &[type="text"]:focus,
  &[type="number"]:focus,
  &[type="date"]:focus {
    -webkit-box-shadow: 0 0 2px 1px #1890ff;
    -moz-box-shadow: 0 0 2px 1px #1890ff;
    box-shadow: 0 0 2px 1px #1890ff;
    border: 1px solid $yellow;
    background: #fafafa;
  }
  &[type="submit"] {
    background: $yellow;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 1.1em;
    margin-bottom: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: darken($yellow, 7%);
    }
    &:focus {
      background: darken($yellow, 7%);
    }
  }
  &[type="checkbox"],
  &[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}
.add_btn {
  background: $yellow;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  width: 25%;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 1.1em;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: darken($yellow, 7%);
  }
  &:focus {
    background: darken($yellow, 7%);
  }
}
</style>